// About
.about {
  padding: 80px 0;

  h3 {
    font-size: 30px;
    margin-bottom: 20px;
  }

  p {
    font-size: 16px;
    color: $grey;
    line-height: 1.6;
    font-weight: 300;
  }
}

// Services
.services {
  padding: 80px 0;
  position: relative;
  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#ffffff+0,e5e5e5+50,ffffff+100 */
background: #ffffff; /* Old browsers */
background: -moz-linear-gradient(top,  #ffffff 0%, #e5e5e5 50%, #ffffff 100%); /* FF3.6-15 */
background: -webkit-linear-gradient(top,  #ffffff 0%,#e5e5e5 50%,#ffffff 100%); /* Chrome10-25,Safari5.1-6 */
background: linear-gradient(to bottom,  #ffffff 0%,#e5e5e5 50%,#ffffff 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#ffffff',GradientType=0 ); /* IE6-9 */




  // &:after {
  //   content: "";
  //   position: absolute;
  //   left: 0;
  //   right: 0;
  //   top: 0;
  //   height: 100%;
  //   background: $dark-grey;
  //   transform: skew(0, -6deg);
  //   z-index: -1;
  // }

  .services-list {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    margin: 0 -20px;
    max-width: 1060px;
    margin: 0 auto;

    li {
      width: calc(25% - 20px);
      margin-right: 20px;
      margin-bottom: 20px;
      border-radius: 4px;
      background-color: #fff;
      overflow: hidden;
      box-shadow: 0px 4px 5px 2px rgba(232,232,232,1);

      &:nth-child(4n) {
        margin-right: 0;
      }
  
      &:last-child,
      &:nth-child(7) {
        .image-holder {
          img {
            max-width: 180%;
          }
        }
      }
    }

    .text-holder {
      padding: 20px;
    }

    h4 {
      font-size: 22px;
      margin-bottom: 10px;
      color: #000;
    }

    p {
      font-size: 12px;
      color: #8a8a8a;
      font-family: 'Open Sans', sans-serif;
    }

      .image-holder {
        width: 100%;
        overflow: hidden;
        height: 160px;
        position: relative;

        a {
          &:before {
            content: '';
            width: 100%;
            height: 100%;
            background-color: rgba(0,0,0,.2);
            display: block;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            z-index: 10;
            position: absolute;
          }

          &:hover {
            &:before {
              background-color: transparent;
            }

            img {
              transform: translate(-50%, -50%) scale(1.1, 1.1);
              -webkit-transform: translate(-50%, -50%) scale(1.1, 1.1);
            }
          }
        }

        img {
          max-width: 105%;
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%) scale(1,1);
          -webkit-transform: translate(-50%, -50%) scale(1,1);
          transition: all 0.2s linear;
        }
      }
    }
  }

// Prices
.prices {
  padding: 60px 0;

  .table {
    border: 1px solid $purple;
    text-align: center;
    margin-top: 60px;

    .tr {
      display: flex;
      border: 1px solid $purple;

      &:first-child {
        background-color: $purple;
      }

      .th {
        p {
          font-size: 20px;
          color: #fff;
          font-weight: 600;
        }
      }

      .tc {
        padding: 8px 0;

        &:first-of-type {
          width: 70%;
        }

        &:last-of-type {
          width: 30%;
          border-left: 2px solid $purple;
        }
      }

      p {
        margin: 0;
        font-size: 16px;
        text-transform: uppercase;
      }
    }
  }

  &.genetic-prices {
    .table {
      text-align: left;

      .tr {        
        .tc {
        padding: 10px 20px;

          &:first-of-type {
            width: 40%;
          }

          &:nth-child(2) {
            width: 40%;
            border-left: 2px solid $purple;
            
            p {
              text-transform: none;
              margin-bottom: 14px;
            }
          }

          &.th{
            &:nth-child(2) {
              p {
                text-transform: uppercase;
                margin-bottom: 0;
              }
            }
          }
  
          &:last-of-type {
            width: 20%;
          }
        }
      }
    }
  }
}

// Call to action
.cta {
  padding: 60px 30px;
  position: relative;
  border-bottom: 1px solid #fff;
  background-color: $dark-grey;
  display: flex;
  justify-content: space-between;
  align-items: center;

  h3 {
    color: #fff;
    font-size: 40px;
  }

  h4 {
    color: #f2f2f2;
    font-size: 32px;
  }
}


// Contact

.contact {
  position: relative;

  .contact-map {
    height: 400px;
  }
  
  .contact-info {
    max-width: 800px;
    margin: 0 auto;
    position: relative;
    overflow: hidden;
    border-radius: 4px;
    z-index: 10;
    top: -100px;
    background-color: #fff;
    text-align: center;
    box-shadow: 0px 0px 10px 0px rgba(212,212,212,1);
    
    .contact-details {
    padding: 60px 40px;
    }

    .contact-photo {
      height: 400px;
      overflow: hidden;
    }


    h3 {
      font-size: 26px;
      margin-bottom: 40px;
      color: #949494;

      &:last-of-type {
        margin-bottom: 0;
      }
      
      span {
        display: block;
        margin-top: 10px;
        font-size: 32px;
        color: $dark-grey;
      }
    }
  }
}


// Carousel

.photo-carousel {
  max-width: 1440px;
  margin: 0 auto;
  display: flex;
  position: relative;

  .view-carousel {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    background-color: #000;
    opacity: 0;

    &:after {
      content: 'Vezi imaginile';
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      -webkit-transform: translate(-50%, -50%);
      display: block;
      color: #fff;
      font-size: 32px;
      text-align: center;
    }

    &:hover {
      opacity: 0.3;
    }
  }

  a {
    img {
      width: 100%;
    }
  }
}