.footer {
    background-color: $dark-grey;
    display: flex;
    justify-content: space-between;
    padding: 20px 30px;
    align-items: center;

    p {
        color: #fff;
        margin: 0;
    }

    a {
        &:hover {
            svg {
                fill: #ccc;
            }
        }
    }

    svg {
        width: 32px;
        height: 32px;
        fill: #fff;
    }
}