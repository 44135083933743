a {
  text-decoration: none;

  &:hover {
    text-decoration: none;
  }
}

img {
  max-width: 100%;
}


body, html {
    font-family: 'Montserrat', sans-serif;
    font-size: 14px;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.fixer {
    padding-top: 68px;
    // padding-top: 148px; // delete when removing the banner
}

h1, h2, h3, h4, h5 {
  font-family: Oswald, sans-serif;
}