@media only screen and (max-width: 1100px) {
  .services {
    .services-list {
      max-width: 520px;
      li {
        width: calc(50% - 10px);

        &:nth-child(2n) {
          margin-right: 0;
        }
      }
    }
  }

  .cta {
    flex-direction: column;
    text-align: center;

    .cta-right {
      margin-top: 40px;
    }
  }
}

@media only screen and (max-width: 850px) {
  .header .menu {
    display: none;
  }

  .mobile-menu {
    display: block;
    
    &.is-open {
      height: 100vh;
    }
  }
  
  .logo {
    img {
      width: 166px;
      max-width: 166px;
    }
  }
}

@media only screen and (max-width: 768px) {
  .test-about {
    padding-bottom: 0;
  }

  .prices {
    padding-top: 0;
  }

  .hero-text {
    h1 {
      font-size: 50px;
    }
    
    h2 {
      font-size: 42px;
    }
  }

  .services {
    padding: 0 20px;

    .services-list {
      .image-holder {
        a {
          &:hover {
            img {
              transform: translate(-50%, -50%);
              -webkit-transform: translate(-50%, -50%);
            }
          }
        }
  
        img {
          transform: translate(-50%, -50%);
          -webkit-transform: translate(-50%, -50%);
        }
      }
    }
  }
  .prices {
    .table {
      text-align: center !important;
      border: none;

      .tr {
        flex-direction: column;
        margin-bottom: 20px;

        .tc {
          width: 100% !important;

          &:last-of-type {
            background-color: #333;
            color: #fff;
          }

          &:nth-child(2n) {
            border-left: none !important;
            border-top: 1px solid $purple;
          }
        }
      }
    }
  }

  .photo-carousel {
    flex-wrap: wrap;
    
    a {
      width: 50%;

      &.view-carousel {
        display: none;
      }
    }
  }
}

@media only screen and (max-width: 460px) {
  .hero-text {
    h1 {
      font-size: 46px;
    }
    
    h2 {
      font-size: 36px;
    }
  }

  .about {
    text-align: center;

    h3 {
      margin-top: 20px;
    }
  }

  .eat-about {
    ul {
      margin: 0;
      padding: 0;
      list-style: none;
    }
  }

  .services {
    .services-list {
      width: 100%;

      li {
        width: 100%;
        margin: 0 0 20px 0;
      }
    }
  }

  .footer {
    flex-direction: column;
    text-align: center;

    .social {
      margin-top: 10px;
    }
  }

  .contact .contact-info {
    top: 0;
    box-shadow: none;

    .contact-details {
      padding: 40px 20px;
    }

    .contact-photo {
      height: auto;
    }

    h3 {
      span {
        font-size: 24px;
      }
    }
  }
}