.scroll-downs {
    position: absolute;
    bottom: 60px;
    left: 50%;
    transform: translateX(-50%);
    margin: auto;
    
    width: 20px;
    height: 35px;
    z-index: 1;
  }
  .mousey {
    width: 3px;
    padding: 10px 10px;
    height: 17px;
    border: 2px solid #fff;
    border-radius: 25px;
    opacity: 0.75;
    box-sizing: content-box;
  }
  .scroller {
    width: 2px;
    height: 6px;
    border-radius: 25%;
    background-color: #fff;
    animation-name: scroll;
    animation-duration: 2.2s;
    animation-timing-function: cubic-bezier(.15,.41,.69,.94);
    animation-iteration-count: infinite;
  }
  @keyframes scroll {
    0% { opacity: 0; }
    10% { transform: translateY(0); opacity: 1; }
    100% { transform: translateY(15px); opacity: 0;}
  }