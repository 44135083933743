// BANNER STYLES

// .banner {
//   background-color: #000;  
//   width: 100%;
//   top: 0;
//   left: 0;
//   z-index: 1000;
//   position: fixed;
//   display: flex;
//   align-items: center;
//   background-image: url(../images/christmas.jpg);
//   background-size: cover;
//   background-position: 50% 74%;
//   background-repeat: no-repeat;


//   a {
//     padding: 21px 30px;
//     color: #fff;
//     display: block;

//     &:hover {
//       color: #ffcdcd;
//     }
//   }

//   h1 {
//     font-size: 32px;
//     margin: 0;
//     font-weight: 600;
//     text-shadow: 2px 2px #000;

//     span {
//       font-weight: 400;
//     }
//   }

//   @media screen and (max-width: 1110px) {
//     height: 80px;
//     align-items: center;

//     h1 {
//       font-size: 28px;
//       text-align: center;
//     }
//   }

//   @media screen and (max-width: 550px) {
//     background-position: 50% 74%;

//     h1 {
//       font-size: 18px;
//     }
//   }
// }

.header {
  // top: 80px !important; // remove this when removing the banner
  background-color: $purple;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 14px 30px;
  position: fixed;
  width: 100%;
  left: 0;
  top: 0;
  z-index: 100;

  .logo {
    max-width: 166px;
    position: relative;
    z-index: 1000;
  }

  .menu {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;

    li {
      margin-right: 20px;

      &:last-child {
        margin-right: 0;
      }
    }

    a {
      display: inline-block;
      font-size: 14px;
      line-height: 30px;
      padding: 0px 10px;
      font-weight: 600;
      position: relative;
      color: #fff;
      text-decoration: none;
      opacity: 1;
      transition: opacity 0.3s ease-in-out;

      &:before {
        content: ' ';
        width: calc(100% - 20px);
        height: 1px;
        background: #fff;
        position: absolute;
        bottom: 0;
        left: 10px;
        transform: scale(0);
        transition: transform 0.3s ease-in-out, -webkit-transform 0.3s ease-in-out;
      }

      &:hover {
        &:before {
          transform: scale(1);
          transition: transform 0.3s ease-in-out, -webkit-transform 0.3s ease-in-out;
        }
      }
    }
  }
}

.hero {
  height: calc(100vh - 68px);
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  position: relative;

  &-text {
    position: relative;
    z-index: 5;
    text-align: center;

    h1 {
      color: #f8f8f8;
      font-size: 72px;
      margin-bottom: 20px;
      text-transform: uppercase;
    }

    h2 {
      color: #f8f8f8;
      font-size: 52px;
      font-weight: 400;
    }
  }

  &-button {
    .cta-button {
      margin-top: 60px;
    }
  }

  &-img {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }

  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: rgba(#000, .5);
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }
}


// Mobile menu

.mobile-menu {
  display: none;
}

/* Main menu positionning */
.main-nav {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  text-align: center;
  background: #FFF;
  opacity: 0;
  z-index: -1;
  visibility: hidden;
  transition: all .375s;
}

.main-nav.is-open {
  opacity: 1;
  z-index: 100;
  visibility: visible;
}

/* Yellow band effect */
.main-nav::before {
 content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: -15px;
  background: $purple;
  transform-origin: 0 0;
  transform: skew(-14deg) translateX(-120%);
  transition: all .275s .1s;
}

.main-nav.is-open::before {
  transform: skew(-14deg) translateX(0);
}

/* Skewing effect on menu links */
.main-nav ul {
  display: inline-flex;
  flex-direction: column;
  height: 93%; /* Should be 100%, but we have a notice message :D */
  align-items: flex-end;
  justify-content: center;
  transform: translateX(-18%) skew(-16deg);
}

.main-nav li {
  display: block;
  margin: .5rem 0;
  text-align: right;
  transform: skew(16deg);
}

/* Apparition effect on links */
.main-nav a {
  opacity: 0;
  transform: translateY(-10px);
}

.main-nav.is-open a {
  opacity: 1;
  transform: translateY(0);
}
.main-nav li:nth-child(1) a {
transition: all 275ms 175ms
}
.main-nav li:nth-child(2) a {
transition: all 275ms 225ms
}
.main-nav li:nth-child(3) a {
transition: all 275ms 275ms
}
.main-nav li:nth-child(4) a {
transition: all 275ms 325ms
}
.main-nav li:nth-child(5) a {
transition: all 275ms 375ms
}
.main-nav li:nth-child(6) a {
  transition: all 275ms 375ms
  }

/* Decoration */
.main-nav ul,
.main-nav li {
list-style: none;
padding: 0;
}
.main-nav a {
display: block;
padding: 12px 0;
color: #fff;
font-size: 1.4em;
text-decoration: none;
font-weight: bold;
}


/* Burger Style: @see: https://codepen.io/CreativeJuiz/full/oMZNXy */
.open-main-nav {
position: absolute;
top: 20px;
padding-top: 20px;
right: 15px;
z-index: 1000;
background: none;
border: 0;
cursor: pointer;
}
.open-main-nav:focus {
outline: none;
}
.burger {
position: relative;
display: block;
width: 28px;
height: 4px;
margin: 0 auto;
background: #fff;
transform: skew(5deg);
transition: all .275s;
}

.burger:after,
.burger:before {
content: '';
display: block;
height: 100%;
background: #fff;
transition: all .275s;
}

.burger:after {
transform: translateY(-12px) translateX(-2px) skew(-20deg);
}

.burger:before {
transform: translateY(-16px) skew(-10deg);
}

/* Toggle State part */
.burger.is-open {
transform: skew(5deg) translateY(-8px) rotate(-45deg);
}

.burger.is-open:before {
transform: translateY(0px) skew(-10deg) rotate(75deg);
}

.burger.is-open:after {
transform: translateY(-12px) translateX(10px) skew(-20deg);
  opacity: 0;
}

/* MENU Text part */

.mobile-menu {
position: absolute;
top: 0; 
right: 0;
bottom: 0; 
left: 0;
overflow: hidden;
background: $purple;
}


.menu-open {
  .header {
    height: 100%;
    justify-content: flex-start;
    align-items: flex-start;
  }
}