$purple: #5E005F;
$grey: rgb(78, 78, 78);
$black: #000;
$dark-grey: #233142;
$cta: #ff9800;
$cta-hover: #fdb140;


@import './components/_reset';
@import './components/_general';
@import './components/_header';
@import './components/_scroll';
@import './components/_sections';
@import './components/_footer';
@import './components/_mobile';
@import './components/_lightbox';