.cta-button {
    background-color: $cta !important;
    padding: 10px 60px !important;
    font-size: 18px !important;
    color: #fff !important;
    transform: scale(1,1) !important;
    transition: transform 0.1s linear !important;

    &:hover {
      background-color: $cta-hover !important;
      transform: scale(1.1,1.1) !important;
      color: #fff !important;
    }
  }

  .new-product {
    font-weight:500;
    color: rgb(214, 0, 0);
  }

  .top-c-100 {
    position: fixed;
    right: 20px;
    bottom: 20px;
    z-index: 9999;
    width: 60px;
    height: 60px;
    overflow: hidden;
    border-radius: 4px;

    img {
      max-width: 100%;
    }
  }